import { useUserPermissionsClient } from '~~/sharedUtils/useUserPermissionsClient'

const { permissionTypes } = useUserPermissionsClient()

// Available apiTargets
// planned_spend_pnl, estimated_spend_pnl, throttle, EBITDA_actual,
// EBITDA_budget, OH_Budget, revenue, day_clicks_and_search, hr_growth,
// hr_search, day_vs_last_year
let interval = 300000
const intervalOffset = 210
export default {
  csm_all: {
    apiTarget: 'csm',
    permission: permissionTypes.user,
    apiMethod: 'all',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 1,
  },
  planned_spend_pnl: {
    apiTarget: 'csm',
    permission: permissionTypes.user,
    apiMethod: 'planned_spend_pnl',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 1,
  },
  estimated_spend_pnl: {
    apiTarget: 'csm',
    permission: permissionTypes.user,
    apiMethod: 'estimated_spend_pnl',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
  },
  throttle: {
    apiTarget: 'csm',
    permission: permissionTypes.user,
    apiMethod: 'throttle',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 1,
  },
  EBITDA_all: {
    apiTarget: 'ebitda',
    permission: permissionTypes.user,
    apiMethod: 'all',
    displayText: 'Actual',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 16,
  },
  EBITDA_actual: {
    apiTarget: 'ebitda',
    permission: permissionTypes.user,
    apiMethod: 'EBITDA_actual',
    displayText: 'Actual',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 16,
  },
  EBITDA_budget: {
    apiTarget: 'ebitda',
    permission: permissionTypes.user,
    apiMethod: 'EBITDA_budget',
    displayText: 'Budget',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 16,
  },
  OH_Budget: {
    apiTarget: 'ebitda',
    permission: permissionTypes.user,
    apiMethod: 'OH_Budget',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 16,
  },
  revenue: {
    apiTarget: 'ebitda',
    permission: permissionTypes.user,
    apiMethod: 'revenue',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'year',
    queryChangeAmount: 16,
  },
  day_clicks_and_search: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'day_clicks_and_search',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 10,
    resultsNumber: 11,
  },
  hr_growth: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'hr_growth',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 1,
  },
  hr_search: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'hr_search',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 1,
  },
  day_vs_last_year: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'day_vs_last_year',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
  },
  day_vs_any_year: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'day_vs_any_year',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
  },
  per_click_cost: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'per_click_cost',
    displayText: 'Actual',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 10,
    resultsNumber: 11,
  },
  ota_market_share_default: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ota_market_share_default',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 11,
  },
  ota_market_share_ETG: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ota_market_share_ETG',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 11,
  },
  ota_market_share_competition: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ota_market_share_competition',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 11,
  },
  ota_market_share_custom: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ota_market_share_custom',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 11,
  },
  ota_market_share_top: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ota_market_share_top',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 11,
    resultsNumber: 10,
  },
  analytics_paid_vs_organic: {
    apiTarget: 'etraveli',
    permission: permissionTypes.user,
    apiMethod: 'analytics_paid_vs_organic',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 11,
    resultsNumber: 10,
  },
  meta_all_market_share: {
    apiTarget: 'etraveli',
    permission: permissionTypes.user,
    apiMethod: 'meta_all_market_share',
    displayText: '',
    intervalTime: interval += intervalOffset,
  },
  net_revenue: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'net_revenue',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 22,
    resultsNumber: 21,
  },
  budget_oh: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'budget_oh',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 22,
    resultsNumber: 21,
  },
  estimated_market_spend: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'estimated_market_spend',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 22,
    resultsNumber: 21,
  },
  ebitda_budget: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ebitda_budget',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 22,
    resultsNumber: 21,
  },
  ebitda_actual: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ebitda_actual',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 22,
    resultsNumber: 21,
  },
  ebitda_difference: {
    apiTarget: 'enterprise',
    permission: permissionTypes.user,
    apiMethod: 'ebitda_difference',
    displayText: '',
    intervalTime: interval += intervalOffset,
    intervalHandle: null,
    argumentType: 'date',
    queryChangeAmount: 22,
    resultsNumber: 21,
  },
}
