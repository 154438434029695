import { useUserPermissionsClient } from '~~/sharedUtils/useUserPermissionsClient'

const { permissionTypes } = useUserPermissionsClient()

let key = 0

export const infoCardTypes = [
  {
    name: 'NumericalCardPoll',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Dash - Today\'s profit',
    key: key += 1,
    props: {
      apiKey: 'dailyProfit',
      apiEndpoint: 'dailyProfit/dailyProfit',
      title: 'Today\'s profit',
      width: 'full',
      // 55 minutes
      frequency: 3300000,
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Enterprise - Net Revenue',
    key: key += 1,
    props: {
      apiEndpoint: 'net_revenue',
      apiKey: 'net_revenue',
      title: 'Net Revenue',
      width: 'third',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      sortByItems: [],
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Enterprise - Budget OH',
    key: key += 1,
    props: {
      apiEndpoint: 'budget_oh',
      apiKey: 'budget_oh',
      title: 'Budget OH',
      width: 'third',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      sortByItems: [],
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Enterprise - Estimated market spend',
    key: key += 1,
    props: {
      apiEndpoint: 'estimated_market_spend',
      apiKey: 'estimated_market_spend',
      title: 'Estimated market spend',
      width: 'third',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      sortByItems: [],
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Enterprise - EBITDA Budget',
    key: key += 1,
    props: {
      apiEndpoint: 'ebitda_budget',
      apiKey: 'ebitda_budget',
      title: 'EBITDA Budget',
      width: 'third',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      sortByItems: [],
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Enterprise - EBITDA Actual',
    key: key += 1,
    props: {
      apiEndpoint: 'ebitda_actual',
      apiKey: 'ebitda_actual',
      title: 'EBITDA Actual',
      width: 'third',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      sortByItems: [],
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    fullName: 'Enterprise - EBITDA Difference',
    key: key += 1,
    props: {
      apiEndpoint: 'ebitda_difference',
      apiKey: 'ebitda_difference',
      title: 'EBITDA Difference',
      width: 'third',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      sortByItems: [],
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Clickout per hour',
    props: {
      apiKey: 'hr_growth',
      // InfoCard Props
      title: 'Clickout per hour',
      width: 'full',
      // BaseChart props
      type: 'bar',
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Searches per hour',
    props: {
      apiKey: 'hr_search',
      // InfoCard Props
      title: 'Searches per hour',
      width: 'full',
      currentSortBy: {
        index: 2,
        value: 'Current month',
      },
      // BaseChart props
      type: 'bar',
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Day click rate',
    props: {
      apiKey: 'day_clicks_and_search',
      dataIdentifier: 'rate',
      tableDataIdentifiers: ['click', 'search', 'rate'],
      // InfoCard Props
      title: 'Day click rate',
      width: 'full',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: true,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Day searches',
    props: {
      apiKey: 'day_clicks_and_search',
      dataIdentifier: 'search',
      tableDataIdentifiers: ['click', 'search', 'rate'],
      // InfoCard Props
      title: 'Day searches',
      width: 'half',
      // BaseChart props
      type: 'bar',
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Day clicks',
    props: {
      apiKey: 'day_clicks_and_search',
      dataIdentifier: 'click',
      tableDataIdentifiers: ['click', 'search', 'rate'],
      // InfoCard Props
      title: 'Day clicks',
      width: 'half',
      // BaseChart props
      type: 'bar',
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Day VS Last Year',
    props: {
      apiKey: 'day_vs_last_year',
      // InfoCard Props
      title: 'Day VS Last Year',
      width: 'full',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: true,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Day VS 2019',
    props: {
      apiKey: 'day_vs_any_year',
      // InfoCard Props
      title: 'Day VS 2019',
      width: 'full',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: true,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Per click cost',
    props: {
      apiKey: 'per_click_cost',
      dataIdentifier: 'per_click_cost',
      // InfoCard Props
      title: 'Per click cost',
      width: 'half',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: false,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - OTA shares - Overview',
    props: {
      apiKey: 'ota_market_share_default',
      // InfoCard Props
      title: 'OTA shares - Overview',
      width: 'half',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: false,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - OTA shares - ETG brands',
    props: {
      apiKey: 'ota_market_share_ETG',
      // InfoCard Props
      title: 'OTA shares - ETG brands',
      width: 'half',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: false,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - OTA shares - Non ETG brands',
    props: {
      apiKey: 'ota_market_share_competition',
      // InfoCard Props
      title: 'OTA shares - Non ETG brands',
      width: 'half',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: false,
    },
  },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - OTA shares - Top 6 brands',
    props: {
      apiKey: 'ota_market_share_top',
      // InfoCard Props
      title: 'OTA shares - Top 6 brands per day',
      width: 'full',
      // BaseChart props
      type: 'bar',
      stackBars: true,
      convertYAxisToPercentage: false,
    },
  },
  // {
  //   name: 'GraphCard',
  //   type: 'Graph',
  //   permission: permissionTypes.user,
  //   key: key += 1,
  //   fullName: 'Google Analytics - Paid vs organic users',
  //   props: {
  //     apiKey: 'analytics_paid_vs_organic',
  //     // InfoCard Props
  //     title: 'Paid vs organic users',
  //     width: 'full',
  //     // BaseChart props
  //     type: 'bar',
  //     stackBars: true,
  //     convertYAxisToPercentage: true,
  //   },
  // },
  {
    name: 'GraphCard',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'ETraveli - Meta all market share',
    props: {
      apiKey: 'meta_all_market_share',
      // InfoCard Props
      title: 'Meta all market share',
      width: 'half',
      // BaseChart props
      type: 'line',
      convertYAxisToPercentage: true,
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Dash - Time of profitability',
    props: {
      apiEndpoint: 'dailyProfit/timeOfProfit',
      // InfoCard Props
      title: 'Time of profitability',
      width: 'full',
      convertPercentageToTime: true,
      // BaseChart props
      baseChart: {
        type: 'line',
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - Net Revenue',
    props: {
      apiEndpoint: 'googleSheets/monthlyResult',
      apiKey: 'Net Revenue',
      // InfoCard Props
      title: 'Net Revenue',
      width: 'third',
      showQueryDate: false,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'bar',
        options: {
          yaxis: {
            max: 12500000,
          },
        },
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - Marketing Costs',
    props: {
      apiEndpoint: 'googleSheets/monthlyResult',
      apiKey: 'Marketing',
      // InfoCard Props
      title: 'Marketing Costs',
      width: 'third',
      showQueryDate: false,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'bar',
        options: {
          yaxis: {
            max: 12500000,
          },
        },
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - EBITDA',
    props: {
      apiEndpoint: 'googleSheets/monthlyResult',
      apiKey: 'EBITDA',
      // InfoCard Props
      title: 'EBITDA',
      width: 'third',
      showQueryDate: false,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'bar',
        options: {
          yaxis: {
            max: 12600000,
            min: 0,
          },
        },
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - OH Cost',
    props: {
      apiEndpoint: 'googleSheets/monthlyResult',
      apiKey: 'OH Cost',
      // InfoCard Props
      title: 'OH Cost',
      width: 'half',
      showQueryDate: false,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'bar',
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - OWC',
    props: {
      apiEndpoint: 'googleSheets/monthlyResult',
      apiKey: 'OWC',
      // InfoCard Props
      title: 'OWC',
      width: 'half',
      showQueryDate: false,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'bar',
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - Top of Mind - Brand Awareness',
    props: {
      apiEndpoint: 'googleSheets/brandRecognition',
      apiKey: 'Top of Mind - Brand Awareness',
      // InfoCard Props
      title: 'Top of Mind - Brand Awareness',
      width: 'third',
      showQueryDate: false,
      numberOfDaysToSubtractWhenChangingDate: 365,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'line',
        convertYAxisToPercentage: true,
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - Top of Mind - Ad recall',
    props: {
      apiEndpoint: 'googleSheets/brandRecognition',
      apiKey: 'Top of Mind - Ad recall',
      // InfoCard Props
      title: 'Top of Mind - Ad recall',
      width: 'third',
      showQueryDate: false,
      numberOfDaysToSubtractWhenChangingDate: 365,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'line',
        convertYAxisToPercentage: true,
      },
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - In mind - Brand Awareness',
    props: {
      apiEndpoint: 'googleSheets/brandRecognition',
      apiKey: 'In mind - Brand Awareness',
      // InfoCard Props
      title: 'In mind - Brand Awareness',
      width: 'third',
      showQueryDate: false,
      numberOfDaysToSubtractWhenChangingDate: 365,
      // BaseChart props
      baseChart: {
        legendLarge: true,
        scrollable: true,
        type: 'line',
        convertYAxisToPercentage: true,
      },
    },
  },
  {
    name: 'NumericalCard',
    type: 'Number',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Google Spreadsheet - Remaining to Bonus',
    props: {
      isBridge: false,
      apiEndpoint: 'googleSheets/monthlyResult',
      apiKey: 'Remaining to Bonus',
      // InfoCard Props
      title: 'Remaining to Bonus',
      width: 'full',
      showQueryDate: false,
      numberOfDaysToSubtractWhenChangingDate: 365,
    },
  },
  {
    name: 'GraphCardNew',
    type: 'Graph',
    permission: permissionTypes.user,
    key: key += 1,
    fullName: 'Enterprise - Users per day - Search',
    props: {
      apiEndpoint: 'analytics/users/users',
      // InfoCard Props
      title: 'Users per day - Search',
      width: 'half',
      showQueryDate: false,
      // BaseChart props
      baseChart: {
        scrollable: true,
        type: 'line',
      },
    },
  },
]
